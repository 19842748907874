import React, { useState } from "react";
import axios from "axios";
import querystring from "querystring";

import RenderForm from "~wrappers/renderForm";

const ContactFormComponent = ({ locale, data: { form } }) => {
  const submitForm = (data) => {
    const url = "/.netlify/functions/sendMail";
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    setMessageState("loading");

    axios
      .post(url, querystring.stringify(data), config)
      .then(() => {
        setTimeout(() => setMessageState("success"), 500);
      })
      .catch(() => {
        setTimeout(() => setMessageState("error"), 500);
      });
  };

  const [renderMessageState, setMessageState] = useState();

  if (!locale || !form) {
    return "";
  }

  return (
    <section className="stf-contact-form">
      <div className="columns is-tablet is-variable is-4-mobile is-8-desktop">
        <div className="column">
          <div className="stf-contact-form__content">
            <RenderForm
              locale={locale}
              form={form}
              submitForm={submitForm}
              renderMessageState={renderMessageState}
            >
              <button
                onClick={(e) => e.target.blur()}
                type="submit"
                className="stf-button stf-button--white stf-button--full"
              >
                {form.button}
              </button>
            </RenderForm>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactFormComponent;
