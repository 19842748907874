import React from "react";

const ScrollText = () => {
  const text = "AUGMENTED REALITY PUBLISHING";

  return (
    <div className="stf-scroll-text">
      <p>{text}</p>
    </div>
  );
};
export default ScrollText;
