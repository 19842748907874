import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "gatsby";

import { localizedUrl } from "~utils";

const FormComponent = ({
  locale,
  form,
  children,
  submitForm,
  renderMessageState,
}) => {
  const renderField = (field, key) => {
    if (!field) {
      return;
    }

    const createField = (field) => {
      switch (field.type) {
        case "text":
          return (
            <>
              <label htmlFor={field.name} className="stf-field--label">
                {field.label}
              </label>
              <Field
                type="text"
                id={field.name}
                name={field.name}
                placeholder={field.placeholder}
                className="stf-input"
              />
              <span className="stf-field--error">
                <ErrorMessage name={field.name} />
              </span>
            </>
          );
        case "checkbox":
          return (
            /* eslint-disable */
            <>
              <Field
                type="checkbox"
                name={field.name}
                id={field.name}
                className="stf-checkbox"
              />
              <label htmlFor={field.name}>{field.placeholder}</label>

              {field.dialog && field.dialog.target.includes("dialog") && (
                <button
                  type="button"
                  className="stf-checkbox__dialog"
                >
                  {field.dialog.text}
                </button>
              )}

              {field.dialog &&
                !field.dialog.target.includes("dialog") &&
                !field.dialog.target.includes("http") && (
                  <Link to={localizedUrl(locale, field.dialog.target)}>
                    {field.dialog.text}
                  </Link>
                )}

              <span className="stf-field--error">
                <ErrorMessage name={field.name} />
              </span>
            </>
            /* eslint-enable */
          );
        case "textarea":
          return (
            <>
              <label htmlFor={field.name} className="stf-field--label">
                {field.label}
              </label>
              <Field
                component="textarea"
                id={field.name}
                name={field.name}
                placeholder={field.placeholder}
                className="stf-textarea"
                rows="4"
              />
              <span className="stf-field--error">
                <ErrorMessage name={field.name} />
              </span>
            </>
          );
        case "select":
          return (
            <>
              <label htmlFor={field.name} className="stf-field--label">
                {field.label}
              </label>

              <Field
                as="select"
                id={field.name}
                name={field.name}
                className="stf-select"
              >
                {
                  <option value="" disabled hidden>
                    {field.placeholder}
                  </option>
                }
                {field.options &&
                  field.options.map((option) => (
                    <option
                      key={`field-${field.name}-${option.name}`}
                      name={option.name}
                      value={option.value}
                    >
                      {option.name}
                    </option>
                  ))}

                {field.groupOptions &&
                  field.groupOptions.map((group) => (
                    <optgroup
                      label={group.name}
                      key={`field-${field.name}-${group.name}`}
                    >
                      {group.options.map((option) => (
                        <option
                          key={`field-${field.name}-${option.name}`}
                          name={option.name}
                          value={option.value}
                        >
                          {option.name}
                        </option>
                      ))}
                    </optgroup>
                  ))}
              </Field>
              <span className="stf-field--error">
                <ErrorMessage name={field.name} />
              </span>
            </>
          );
        default:
          return;
      }
    };

    return (
      <div key={key} className={`stf-field stf-field-${field.name}`}>
        {createField(field)}
      </div>
    );
  };

  // Taken from https://github.com/jquense/yup/issues/559
  const getValidationSchema = (schema, config) => {
    const { name, validationType, validations = [] } = config;
    if (!Yup[validationType]) {
      return schema;
    }

    let validator = Yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      if (type === "matches") {
        params[0] = new RegExp(params[0], "g");
      }

      if (type === "oneOf" && params[0] === "true") {
        params[0] = [params[0] === "true"];
      }

      validator = validator[type](...params);
    });

    schema[name] = validator;
    return schema;
  };

  const renderMessage = useCallback(
    (status) => {
      if (!status) {
        return;
      }

      setFormState({
        status: status,
        message: form.messages[`${status}`],
      });

      if (status !== "loading") {
        setTimeout(() => {
          setFormState({
            status: "",
            message: "",
          });
        }, 3500);
      }
    },
    [form]
  );

  const renderForm = () => {
    if (!form.initialValues) {
      form.initialValues = {};
      form.fields.forEach((field) => (form.initialValues[field.name] = ""));
    }

    const generatedSchema = form.fields.reduce(getValidationSchema, {});

    if (!generatedSchema) {
      return "";
    }

    form.schema = Yup.object().shape(generatedSchema);
    return (
      <Formik
        initialValues={form.initialValues}
        validationSchema={form.schema}
        onSubmit={(result, { resetForm }) => {
          submitForm(result);
          resetForm();
        }}
      >
        <Form>
          <div className="stf-form__fields">
            {form.fields.map((field) =>
              renderField(field, `stf-field-${field.name}`)
            )}
          </div>
          {children}
        </Form>
      </Formik>
    );
  };

  const [formState, setFormState] = useState({
    status: "",
    message: "",
  });

  useEffect(() => {
    renderMessage(renderMessageState);
  }, [renderMessage, renderMessageState]);

  if (!form || !form.name || !form.fields) {
    return "";
  }

  return (
    <div className="stf-form">
      <div className={`stf-form__message ${formState.message ? "" : "stf-hidden"}`}>
        <p>{formState.message}</p>
      </div>

      {renderForm()}
    </div>
  );
};

export default FormComponent;
