import React from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";

const Head = ({
  pageMetadata,
  siteMetadata,
  locale,
}) => {
  const getMetadata = (type) => {
    return pageMetadata && pageMetadata[type] ? pageMetadata[type] : siteMetadata[type];
  };

  const title = getMetadata('title');
  const description = getMetadata('description');
  const keywords = getMetadata('keywords');
  const { author, siteUrl } = siteMetadata;

  return (
    <Helmet titleTemplate={`%s - Immersive Pub`}>
      <html lang={(locale && locale.key) || "en"} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${withPrefix("/")}uploads/head/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        href={`${withPrefix("/")}uploads/head/favicon-32x32.png`}
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href={`${withPrefix("/")}uploads/head/favicon-16x16.png`}
        sizes="16x16"
      />

      <link
        rel="mask-icon"
        href={`${withPrefix("/")}uploads/head/safari-pinned-tab.svg`}
        color="#ff4400"
      />

      <meta name="theme-color" content="#fff" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={siteUrl} />
      <meta
        property="og:image:secure_url"
        content={`${siteUrl}/uploads/head/og-image-v4.png`}
      />
      <meta
        property="og:image"
        content={`${siteUrl}/uploads/head/og-image-v4.png`}
      />
    </Helmet>
  );
};

export default Head;
