import { useState, useEffect, useRef } from "react";

import locales from "~locales";
import viewports from "~viewports";

// I18N
export const localizedSlug = (slug) => {
  for (const locale of locales) {
    if (slug.includes(`.${locale.key}`)) {
      return `/${locale.key}${slug.replace(`.${locale.key}`, "")}`;
    }
  }

  return slug;
};

export const localizedUrl = (currentLocale, url) => {
  if (currentLocale.path === "") {
    if (url === "" || url === "/") {
      return "/";
    }
    return `/${url}/`;
  } else {
    return `/${currentLocale.path}/${url}/`;
  }
};

export const localizedLanguageUrl = (entry, location, currentLocale) => {
  if (entry.key === currentLocale.key) {
    return location.pathname;
  }

  const determinePath = (path) => {
    return path === "" ? "/" : path;
  };

  let sanitizedPath;
  if (entry.path === "") {
    sanitizedPath = location.pathname.substring(3);
    return determinePath(sanitizedPath);
  } else {
    sanitizedPath = location.pathname.replace(`/${currentLocale.path}`, "");
    sanitizedPath = determinePath(sanitizedPath);

    return currentLocale.path === ""
      ? `/${entry.path}/${sanitizedPath}`
      : `/${entry.path}${sanitizedPath}`;
  }
};

export const getLocale = (key) => {
  const defaultLocale = locales.filter((locale) => locale.default)[0];
  if (!key) {
    return defaultLocale;
  }

  const locale = locales.filter((locale) => locale.key === key)[0];
  return locale ? locale : defaultLocale;
};

// Debouncers
export const debounceEvent = (callback, wait) => {
  let timeout = null;
  return (...args) => {
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
  };
};

// Other
export const setBackground = (image) => {
  return {
    background: `url(${image}) no-repeat center center`,
    backgroundSize: "cover",
  };
};

export const detectPortable = () => {
  const isClient = typeof window === "object";

  if (!isClient) {
    return false;
  }

  const width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );

  return width < viewports.desktop;
};

// Hooks
export function useWindowSize() {
  const getSize = (isClient) => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const isClient = typeof window === "object";

    if (!isClient) {
      return false;
    }

    const handleResize = (isClient) => {
      setWindowSize(getSize(isClient));
    };

    handleResize(isClient);

    window.addEventListener(
      "orientationchange",
      debounceEvent(handleResize, 500)
    );
    return () =>
      window.removeEventListener(
        "orientationchange",
        debounceEvent(handleResize, 500)
      );
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export function useEventListener(eventName, handler, element) {
  if (!element) {
    element = typeof window === "object" ? window : undefined;
  }

  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

const cachedScripts = [];
export function useScript(src, targetLocation) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false,
        });
      } else {
        cachedScripts.push(src);

        // Create script
        let script = document.createElement("script");
        script.src = src;
        script.async = true;

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          });
        };

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(src);
          if (index >= 0) cachedScripts.splice(index, 1);
          script.remove();

          setState({
            loaded: true,
            error: true,
          });
        };

        script.addEventListener("load", onScriptLoad);
        script.addEventListener("error", onScriptError);

        // Add script to document body
        switch (targetLocation) {
          case "head":
            document.head.appendChild(script);
            break;
          case "body":
            document.body.appendChild(script);
            break;
          default:
            break;
        }

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener("load", onScriptLoad);
          script.removeEventListener("error", onScriptError);
        };
      }
    },
    [src, targetLocation] // Only re-run effect if script src changes
  );

  return [state.loaded, state.error];
}
