import React from "react";
import Img from "gatsby-image";

const renderImage = ({ image, alt, modifiers = "" }) => {
  if (!image) {
    return "";
  }

  const altText = alt ? alt : image.name ? image.name : "";

  if (image.childImageSharp && image.childImageSharp.fluid) {
    return (
      <Img
        fluid={image.childImageSharp.fluid}
        alt={altText}
        durationFadeIn={250}
        {...modifiers}
      />
    );
  } else if (image.childImageSharp && image.childImageSharp.fixed) {
    return (
      <Img
        fixed={image.childImageSharp.fixed}
        alt={altText}
        durationFadeIn={250}
        {...modifiers}
      />
    );
  } else if (image.publicURL) {
    return (
      <img src={image.publicURL} alt={altText} {...modifiers} loading="lazy" />
    );
  }
};

export default renderImage;
