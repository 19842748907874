import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import {
  localizedUrl,
} from "~utils";

export const NavigationComponent = ({
  data: {
    pages
  },
  location,
  locale,
}) => {

  const renderPages = (pages, locale) => {
    return (
      <ul className="stf-navigation__pages">
        {pages.map((page) => (
          <li
            key={page.path}
            className="stf-navigation__page"
          >
            {!page.path.includes("scroll") && (
              <Link
                activeClassName="stf-navigation__page--active"
                to={localizedUrl(locale, page.path)}
              >
                {page.name}
              </Link>
            )}

            {page.path.includes("scroll") && (
              <AnchorLink
                to={localizedUrl(locale, page.path.includes('current') ?
                  location.pathname.substring(1) + page.path : page.path
                ).slice(0, -1)}
                stripHash
              >
                {page.name}
              </AnchorLink>
            )}
          </li>
        ))}
      </ul>
    );
  };

  if (!pages || !location || !locale) {
    return "";
  }

  return (
    <nav className="stf-navigation">
      <div className="stf-navigation__list">
        {renderPages(pages, locale)}
      </div>
    </nav>
  );
};

export default NavigationComponent;
