import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import NavigationComponent from "~components/common/navigation";
import RenderImage from "~wrappers/renderImage";
import ScrollText from "~components/layout/scrollText";
import { useWindowSize, localizedUrl } from "~utils";

const Header = ({
  data: { logo, navigation },
  pageHeader,
  location,
  locale,
  hideScrollText,
}) => {
  const { height: pageHeight } = useWindowSize();

  if (!logo || !navigation || !pageHeader || !pageHeight) {
    return "";
  }

  return (
    <header
      className="stf-header"
      style={{ height: hideScrollText ? pageHeight - 150 : pageHeight }}
    >
      {!hideScrollText && <ScrollText />}

      <RenderImage
        image={pageHeader.img}
        modifiers={{
          className: "stf-header__background",
        }}
      />

      <div className="stf-header__content">
        <div className="stf-container">
          <Link to={localizedUrl(locale, "")}>
            <RenderImage
              image={pageHeader.logo ? pageHeader.logo : logo}
              modifiers={{
                className: "stf-header__content__logo",
              }}
            />
          </Link>
          <h1>{pageHeader.title}</h1>
          <p>{pageHeader.text}</p>

          {pageHeader.button && (
            <AnchorLink
              to={pageHeader.button.path}
              stripHash
              className="stf-button stf-button--accent"
            >
              {pageHeader.button.text}
            </AnchorLink>
          )}

          <NavigationComponent
            data={navigation}
            location={location}
            locale={locale}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
