import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { motion, useAnimation, useViewportScroll } from "framer-motion";

import RenderImage from "~wrappers/renderImage";
import { useEventListener } from "~utils";

const ScrollArrow = () => {
  const scrollArrowControls = useAnimation();
  const { scrollYProgress } = useViewportScroll();

  useEventListener("scroll", () =>
    scrollArrowControls.start(
      scrollYProgress.current > 0.25
        ? "scrollArrowVisible"
        : "scrollArrowHidden"
    )
  );

  return (
    <motion.button
      variants={{
        scrollArrowHidden: {
          y: 200,
          transitionEnd: {
            display: "none",
          },
        },
        scrollArrowVisible: { display: "block", y: 0 },
      }}
      initial="scrollArrowHidden"
      animate={scrollArrowControls}
      transition={{
        type: "spring",
        duration: 0.2,
        stiffness: 300,
        damping: 20.5,
      }}
      whileHover={{ scale: 1.1 }}
      className="stf-scroll-arrow"
      onClick={scroll.scrollToTop}
    >
      <span className="stf-screen-reader">Scroll up</span>

      <RenderImage
        type="basic"
        image={{ publicURL: "/uploads/general/icon-scroll-arrow_white.svg" }}
        alt="^"
      />
    </motion.button>
  );
};
export default ScrollArrow;
