import React from "react";
import { graphql, StaticQuery } from "gatsby";

import Head from "./head";
import Header from "./header";
import Footer from "./footer";
import ScrollArrow from "./scrollArrow";

import "~styling/imports.scss";

const LayoutWrapper = ({
  helmetOnly,
  children,
  locale,
  location,
  pageMetadata,
  pageHeader,
  hideScrollText,
}) => {
  const renderWrapper = (data) => {
    const {
      site: { siteMetadata },
      allMarkdownRemark: { edges: components },
    } = data;

    // Most fucked up case. Should never end up here.
    if (!children) {
      return <main className="stf-main">Error - Missing Content</main>;
    }

    // Only show the main if there's no content for the Head
    if (!siteMetadata) {
      return <main className="stf-main">{children}</main>;
    }

    // Only show the Head and the content when no extra global components are wanted (ex: 404 Page)
    if (helmetOnly) {
      return (
        <>
          <Head
            siteMetadata={siteMetadata}
            pageMetadata={pageMetadata}
            locale={locale}
          />
          <main className="stf-main">{children}</main>
        </>
      );
    }

    // Full layout composition with multiple global components
    const sanitizedComponents = [];
    components.forEach((component) => {
      const {
        node: { frontmatter: data },
      } = component;

      sanitizedComponents[data.name] = data.content
        .filter((localizedContent) => {
          Object.entries(localizedContent).forEach(([key, value]) => {
            if (value === null) {
              delete localizedContent[key];
            }
          });

          return localizedContent.locale === locale.key;
        })
        .map((sanitizedContent) => {
          return sanitizedContent[data.name];
        })[0];
    });

    const { header, footer } = {
      ...sanitizedComponents,
    };

    if (!header || !footer) {
      return <main className="stf-main">{children}</main>;
    }

    return (
      <div className="stf-page">
        <Head
          siteMetadata={siteMetadata}
          pageMetadata={pageMetadata}
          locale={locale}
        />
        <Header
          data={header}
          pageHeader={pageHeader}
          locale={locale}
          location={location}
          hideScrollText={hideScrollText}
        />
        <main className="stf-main">{children}</main>
        <Footer data={footer} locale={locale} />
        <ScrollArrow />
      </div>
    );
  };

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              title
              description
              keywords
              author
              siteUrl
            }
          }
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "component" } } }
          ) {
            edges {
              node {
                html
                frontmatter {
                  name
                  content {
                    locale

                    header {
                      logo {
                        name
                        publicURL
                      }
                      navigation {
                        pages {
                          name
                          path
                        }
                      }
                    }

                    footer {
                      logo {
                        name
                        publicURL
                      }
                      contact {
                        title
                        email
                        phone
                      }
                      explore {
                        title
                        items {
                          name
                          path
                        }
                      }
                      copyright
                      form {
                        name
                        messages {
                          success
                          error
                          loading
                        }
                        fields {
                          name
                          type
                          label
                          placeholder
                          validationType
                          validations {
                            type
                            params
                          }
                        }
                        button
                      }
                    }

                    cookies {
                      toggle {
                        text
                        icon {
                          publicURL
                          name
                        }
                      }
                      mdContent
                      form {
                        name
                        fields {
                          name
                          type
                          placeholder
                        }
                        buttons {
                          all
                          current
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => renderWrapper(data)}
    />
  );
};

export default LayoutWrapper;
