import React from "react";
import { Link } from "gatsby";

import RenderImage from "~wrappers/renderImage";
import ContactFormComponent from "~components/common/contactForm";
import { localizedUrl } from "~utils";

const Footer = ({
  data: { logo, contact, explore, copyright, form },
  locale,
}) => {
  if (!logo || !contact || !explore || !copyright || !form) {
    return "";
  }

  return (
    <footer className="stf-footer" id="scroll-current-contact">
      <div className="stf-container">
        <div className="stf-footer__logo">
          <RenderImage image={logo} />
        </div>

        <div className="columns columns-reversed is-desktop">
          <div className="column is-5-desktop">
            <div className="stf-footer__contact">
              <h3> {contact.title} </h3>
              <p>
                <a href={`mailto: ${contact.email}`}>{contact.email}</a>
              </p>
              <p>
                <a href={`tel: ${contact.phone}`}>{contact.phone}</a>
              </p>
            </div>

            <div className="stf-footer__explore">
              <h3> {explore.title} </h3>
              <ul>
                {explore.items.map((item, index) => (
                  <li key={index}>
                    {!item.path.includes("http") && (
                      <Link to={localizedUrl(locale, item.path)}>
                        {item.name}
                      </Link>
                    )}

                    {item.path.includes("http") && (
                      <a
                        href={item.path}
                        target="_blank"
                        rel="nofollow, noreferrer"
                      >
                        {item.name}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="column is-7-desktop">
            <div className="stf-footer__form">
              <ContactFormComponent locale={locale} data={{ form: form }} />
            </div>
          </div>
        </div>
        <div className="stf-footer__copyright">
          <p>{copyright.replace("[year]", new Date().getFullYear())}</p>
          <p>
            Powered by{" "}
            <a href="https://softisfy.com" target="_blank" rel="noreferrer">
              Softisfy
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
